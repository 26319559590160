import React from "react";
import Image from "gatsby-image";
import LinkIcon from "../../Link/LinkIcon";
import { Link } from "gatsby";

const TextBlock = ({ slice, index }) => {
  let image_alignment = "";
  let text_padding = "";
  if (slice.primary?.image_alignment === "Right") {
    image_alignment = "md:flex flex-row-reverse";
    text_padding = "md:pr-gs mt-12";
  } else {
    image_alignment = "md:flex";
    text_padding = "md:pl-gs";
  }
  return (
    <>
      {slice.primary.header?.text && (
        <h1 className="text-2xl md:text-4xl font-normal md:text-center">
          {slice.primary.header.text}
        </h1>
      )}
      {slice.primary.description?.html && (
        <div
          className="mt-8 text-base md:text-lg lg:text-xl leading-relaxed rte max-w-2xl font-light mx-auto lg:text-center"
          dangerouslySetInnerHTML={{
            __html: slice.primary.description.html
          }}
        />
      )}

      {slice.primary.image?.fluid && (
        <div
          className={`${image_alignment} mt-12 md:mt-24 max-w-screen-xl mx-auto`}
        >
          <Image
            placeholderStyle={{ display: "none" }}
            fluid={{
              ...slice.primary.image.fluid,
              aspectRatio: 1.16
            }}
            className="md:w-1/2 flex-shrink-0"
            objectCover
            loading="lazy"
          />
          <div className={`${text_padding} self-center`}>
            {slice.primary.image_heading?.text && (
              <h2 className="mt-4 lg:mt-0 mb-6 text-xl md:text-2xl font-normal">
                {slice.primary.image_heading.text}
              </h2>
            )}
            {slice.primary.image_text?.html && (
              <div
                className="mb-6 mt-5 rte font-light text-base md:text-lg lg:text-xl leading-relaxed"
                dangerouslySetInnerHTML={{
                  __html: slice.primary.image_text?.html
                }}
              />
            )}
            {slice.primary.link?.url && slice.primary.link.url !== "" && (
              <Link
                to={slice.primary.link.url}
                className="inline-flex gap-2 mt-10 items-center pt-2 link"
              >
                <div className="h-5 w-5">
                  <LinkIcon />
                </div>

                <p className="tracking-wider pb-1 uppercase font-medium">
                  {slice.primary.link_text?.text}
                </p>
              </Link>
            )}
          </div>
        </div>
      )}
      <div className="grid lg:grid-cols-3 gap-10">
        {slice.items?.map((item, index) => {
          return (
            <div key={index}>
              {item.panel_header?.text && (
                <h3 className="font-normal text-lg mt-12 md:mt-24">
                  {item.panel_header.text}
                </h3>
              )}
              {item.panel_body?.text && (
                <p className="mt-4 font-light leading-relaxed">
                  {item.panel_body.text}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TextBlock;
