import React from "react";
import Image from "gatsby-image";
import classNames from "classnames";

const ImageAndImageBlock = ({ slice, spacingClassList }) => {
  let imagePosition = [];
  let itemPosition = [];

  if (slice.primary.large_image_position === "Left") {
    imagePosition.push("flex-row-reverse");
    itemPosition.push("items-center lg:items-start");
  } else {
    itemPosition.push("items-center lg:items-end");
  }

  return (
    <div
      className={classNames(
        spacingClassList,
        imagePosition,
        "lg:flex px-ogs justify-center lg:justify-evenly space-y-12 items-center"
      )}
    >
      <div
        className={classNames(
          itemPosition,
          "w-full lg:w-1/5 flex-col justify-center"
        )}
      >
        <div className=" mx-auto max-w-lg">
          <div className="image-container">
            {slice.primary?.small_image?.fluid && (
              <Image
                className="w-full"
                fluid={{
                  ...slice.primary.small_image.fluid,
                  aspectRatio: 0.66
                }}
                loading="lazy"
              />
            )}
          </div>
          {slice.primary?.small_image_text && (
            <p className="mt-4 leading-snug text-sm">
              {slice.primary?.small_image_text.text}
            </p>
          )}
        </div>
      </div>
      <div className={classNames(itemPosition, "w-full lg:w-2/5 flex-col")}>
        <div className="mx-auto max-w-lg">
          <div className="image-container">
            {slice.primary?.large_image?.fluid && (
              <Image
                className="w-full"
                fluid={{
                  ...slice.primary.large_image.fluid,
                  aspectRatio: 0.66
                }}
                loading="lazy"
              />
            )}
          </div>
          {slice.primary?.large_image_text && (
            <p className="mt-4 text-sm leading-snug">
              {slice.primary?.large_image_text.text}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageAndImageBlock;
