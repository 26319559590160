import React from "react";
import Image from "gatsby-image";
import classNames from "classnames";

const ImageBlock = ({ slice, index, frameStyle, spacingClassList }) => {
  let newFrameStyle = frameStyle;

  if (slice.primary?.image?.fluid) {
    if (slice.primary?.image_style === "Image With Frame") {
      newFrameStyle = "m-8 md:m-20";
    } else {
      newFrameStyle = "h-screen";
    }
    return (
      <div key={index} className={classNames(spacingClassList)}>
        {slice.primary?.image?.fluid && (
          <Image
            className={classNames(newFrameStyle)}
            placeholderStyle={{ display: "none" }}
            fluid={{
              ...slice.primary.image.fluid,
              aspectRatio: 2
            }}
          />
        )}
      </div>
    );
  }
  return null;
};

export default ImageBlock;
