import React from "react";

function PlayButton() {
  return (
    <svg className="h-full w-full" viewBox="0 0 53 62" fill="none">
      <path
        d="M1.5 2.42116L51 31L1.5 59.5788L1.5 2.42116Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

export default PlayButton;
