import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";

const FeaturedArticleBlock = ({ slice, index }) => {
  if (!slice || !slice.items) {
    return null;
  }

  return (
    <div key={index} className="px-ogs">
      <h1 className="text-start text-2xl font-normal md:text-4xl">
        {slice.primary?.header?.text || ""}
      </h1>
      {slice.items.map((item, index) => (
        <div
          key={index}
          className="flex flex-col-reverse lg:flex-row border border-primary-black-20 mt-10"
        >
          <div className="p-gs md:flex flex-col justify-between">
            <div className="tracking-widest uppercase font-light text-sm flex justify-start">
              <div className="py-1 mb-4 border tracking-widest text-sm border-primary-black-20 rounded-full px-4">
                {item.featured_article?.text}
              </div>
            </div>
            <div className="mt-auto">
              {item.blog_article_title?.text && (
                <h2 className="font-normal text-2xl mt-4 lg:mt-0">
                  {item.blog_article_title?.text}
                </h2>
              )}
              {item.article_body?.html && (
                <div
                  className="mt-4 text-base md:text-lg lg:text-xl leading-relaxed"
                  dangerouslySetInnerHTML={{
                    __html: item.article_body?.html
                  }}
                />
              )}
              {item.link_text?.text && item.link?.url && (
                <Link
                  to={item.link.url}
                  className="inline-flex gap-2 mt-6 items-center pt-2 link"
                >
                  <p className="tracking-wider uppercase pb-1 text-sm font-semibold">
                    {item.link_text.text}
                  </p>
                </Link>
              )}
            </div>
          </div>
          {item.image?.fluid && (
            <div className="flex-shrink-0 w-full lg:w-1/2 self-center">
              <Image
                placeholderStyle={{ display: "none" }}
                fluid={{
                  ...item.image.fluid,
                  aspectRatio: 1.5
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FeaturedArticleBlock;
