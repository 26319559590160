import React, { useRef, useState } from "react";
import Image from "gatsby-image";
import { Swiper, SwiperSlide } from "swiper/react";
import LeftArrow from "../../Arrows/Left-arrow";
import RightArrow from "../../Arrows/Right-arrow";

const GalleryBlock = ({ items }) => {
  const swiperRef = useRef(null);
  const [imageIndex, setImageIndex] = useState(1);

  const handleSlideChange = swiper => {
    setImageIndex(swiper.activeIndex + 1);
  };

  const handlePrev = () => {
    swiperRef.current.slidePrev();
  };

  const handleNext = () => {
    swiperRef.current.slideNext();
  };

  return (
    <div className=" md:h-5/6 md:space-x-8 items-end mx-12 md:mx-24 lg:mx-40">
      <Swiper
        className="h-full flex-1 overflow-hidden"
        onSlideChange={handleSlideChange}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true
        }}
        onSwiper={swiper => (swiperRef.current = swiper)}
      >
        {items.map((item, index) => (
          <SwiperSlide
            className="h-full flex justify-center items-center"
            key={index}
          >
            {item.image?.fluid && (
              <div
                className="md:h-full w-full md:w-auto overflow-hidden"
                style={{ aspectRatio: `${item.image.fluid.aspectRatio}` }}
              >
                <Image
                  placeholderStyle={{ display: "none" }}
                  fluid={item.image.fluid}
                />
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="mt-8 flex justify-between items-center w-full">
        <div className="flex items-center gap-x-4">
          <div className="cursor-pointer" onClick={handlePrev}>
            <LeftArrow />
          </div>
          <div className="cursor-pointer" onClick={handleNext}>
            <RightArrow />
          </div>
        </div>

        <div className="flex items-center gap-x-4">
          {imageIndex}
          <svg width="57" height="1" fill="none">
            <path
              stroke="#191818"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M56 .5H1"
            />
          </svg>
          {items.length}
        </div>
      </div>
    </div>
  );
};

export default GalleryBlock;
