import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline";

export const Accordion = ({ slice }) => {
  return (
    <div className="bg-white">
      <div className="mx-auto px-6 py-12 lg:py-24 lg:px-8">
        <div className="mx-auto max-w-screen-lg">
          {slice.primary?.accordion_heading.text && (
            <h2 className="text-2xl md:text-4xl md:text-center font-normal">
              {slice.primary?.accordion_heading.text}
            </h2>
          )}
          <dl className="mt-16 space-y-5">
            {slice.items.map((item, index) => (
              <Disclosure as="div" key={index}>
                {({ open }) => (
                  <div
                    className={`border rounded-md p-5 w-full text-left cursor-pointer ${
                      open ? "border-black" : "border-primary-black-20"
                    }`}
                  >
                    <Disclosure.Button as="div">
                      <div className="flex w-full items-start justify-between text-gray-900">
                        <span className="text-base md:text-lg lg:text-xl leading-relaxed font-normal">
                          {item.accordion_title.text}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmIcon
                              className="h-6 stroke-1 w-6 text-primary-black-20"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmIcon
                              className="h-6 stroke-1 w-6 text-primary-black-20"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </div>
                    </Disclosure.Button>
                    <Transition
                      as={React.Fragment}
                      show={open}
                      enter="transition ease-in-out duration-300"
                      enterFrom="opacity-0 max-h-0"
                      enterTo="opacity-100"
                      leave="transition ease-in-out duration-300"
                      leaveFrom="opacity-100 max-h-screen"
                      leaveTo="opacity-0 max-h-0"
                    >
                      <Disclosure.Panel
                        as="dd"
                        className="mt-6 pr-12"
                        onClick={e => e.stopPropagation()}
                      >
                        <div
                          className="text-base md:text-lg rte leading-relaxed font-hairline"
                          dangerouslySetInnerHTML={{
                            __html: item.accordion_text.html
                          }}
                        />
                      </Disclosure.Panel>
                    </Transition>
                  </div>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
