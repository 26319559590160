import React from "react";
import Image from "gatsby-image";
import { Link } from "gatsby";
import classNames from "classnames";
import LinkIcon from "../../Link/LinkIcon";

const PanelsBlock = ({ slice, index, spacingClassList }) => {
  let aspectRatio = 0;
  if (slice.primary?.image_layout === "Landscape") {
    aspectRatio = 2;
  } else {
    aspectRatio = 0.9;
  }
  return (
    <div key={index} className={classNames(spacingClassList, "px-ogs")}>
      {slice?.primary?.title1?.text && (
        <h2 className="md:text-4xl text-2xl font-normal mb-10">
          {slice.primary?.title1?.text}
        </h2>
      )}
      <div className="grid lg:grid-cols-3 gap-x-10 gap-y-10">
        {slice.items?.map((item, index) => {
          return (
            <div key={index} className="w-full mb-auto">
              {item.image?.fluid &&
                (item.link?.url ? (
                  <Link to={item.link?.url}>
                    <Image
                      fluid={{
                        ...item.image?.fluid,
                        aspectRatio: aspectRatio
                      }}
                      placeholderStyle={{ display: "none" }}
                      loading="lazy"
                    />
                  </Link>
                ) : (
                  <Image
                    fluid={{
                      ...item.image?.fluid,
                      aspectRatio: aspectRatio
                    }}
                    placeholderStyle={{ display: "none" }}
                    loading="lazy"
                  />
                ))}
              {item.heading && (
                <h2 className="text-lg font-medium mt-5">
                  {item.heading.text}
                </h2>
              )}
              {item.subheading && (
                <p className="uppercase mt-2">{item.subheading.text}</p>
              )}
              {item.text?.html && (
                <div
                  className="leading-7 font-light rte mt-5"
                  dangerouslySetInnerHTML={{
                    __html: item.text?.html
                  }}
                />
              )}
              {item.link_text?.text && (
                <Link
                  to={item.link?.url}
                  className="inline-flex gap-2 pt-2 items-center link mt-5"
                >
                  <div className="h-5 w-5">
                    <LinkIcon />
                  </div>
                  <p className="tracking-wider uppercase pb-1 text-sm font-medium">
                    {item.link_text.text}
                  </p>
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PanelsBlock;
