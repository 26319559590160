import React, { useState, useRef } from "react";
import PlayButton from "./PlayButton/PlayButton";
import classNames from "classnames";

const VideoBlock = ({ slice, index }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  let videoContainer = [""];
  let container = [""];
  let captionContainer = [""];
  let buttonStyle = [""];

  const video_alignment = slice.primary.video_alignment;
  const video_width = slice.primary.video_width;
  const video_caption = slice.primary.video_caption;

  if (video_width === "Half") {
    if (video_alignment === "Left") {
      container.push("lg:flex flex-row-reverse w-full max-w-screen-xl mx-auto");
      captionContainer.push("pt-6 lg:pt-0 lg:w-1/2 text-center");
      videoContainer.push("video-container lg:w-1/2 px-ogs lg:pl-0 lg:pr-ogs");
      buttonStyle.push("");
    } else if (video_alignment === "Right") {
      container.push("lg:flex flex-row w-full max-w-screen-xl mx-auto");
      captionContainer.push("pt-6 lg:pt-0 lg:w-1/2 text-center");
      videoContainer.push("video-container lg:w-1/2 px-ogs lg:pr-0 lg:pl-ogs");
      buttonStyle.push("");
    } else {
      container.push("px-ogs w-full xl:w-1/2 mx-auto");
    }
  } else if (video_width === "Reduced") {
    container.push("w-full");
    captionContainer.push("text-center md:-mb-12");
    videoContainer.push("w-full px-ogs md:p-20");
    buttonStyle.push("px-ogs md:pl-20 md:pr-20 pt-8 md:pb-8");
  } else {
    container.push("w-full");
    captionContainer.push("text-center md:pb-12");
    videoContainer.push("w-full max-h-screen video-container");
  }

  return (
    <div className={classNames(container)}>
      {video_caption && (
        <div
          className={classNames(
            captionContainer,
            "px-ogs rte font-light text-base md:text-lg lg:text-xl leading-relaxed"
          )}
          dangerouslySetInnerHTML={{
            __html: video_caption.html
          }}
        ></div>
      )}

      <div className={classNames(videoContainer)}>
        <div className="relative h-full w-full">
          {!isPlaying && (
            <div
              className={classNames(
                buttonStyle,
                "hidden md:flex absolute z-50 bottom-0 h-full w-full right-0 top-0 left-0 items-center justify-center"
              )}
              onClick={handlePlay}
            >
              <div className="h-16 w-16">
                <PlayButton />
              </div>
            </div>
          )}
          {slice.primary?.vimeo_url.url && (
            <video
              ref={videoRef}
              className="w-full h-full object-cover"
              src={slice.primary?.vimeo_url.url}
              key={index}
              controls
            ></video>
          )}
          {slice.primary?.video.url && (
            <video
              ref={videoRef}
              className="w-full h-full object-cover"
              src={slice.primary?.video.url}
              key={index}
              controls
            ></video>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoBlock;
