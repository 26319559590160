import React from "react";

function LinkIcon() {
  return (
    <svg
      className="h-full w-full"
      fill="none"
      stroke="#191818"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <path d="M15.3 9H.85M7.65 16.2l7.434-6.708A.655.655 0 0 0 15.3 9a.67.67 0 0 0-.216-.491L7.65 1.8" />
    </svg>
  );
}

export default LinkIcon;
