import React from "react";
import classNames from "classnames";

const YoutubeEmbed = ({ slice, index }) => {
  let videoContainer = [""];
  let container = [""];
  let captionContainer = [""];
  let buttonStyle = [""];

  const video_alignment = slice.primary.video_alignment;
  const video_width = slice.primary.video_width;
  const video_caption = slice.primary.youtube_video_caption;
  const video_embed = slice.primary.youtube_video_embed;

  if (video_width === "Half") {
    if (video_alignment === "Left") {
      container.push("lg:flex flex-row-reverse w-full max-w-screen-xl mx-auto");
      captionContainer.push("pt-6 lg:pt-0 lg:w-1/2 text-center");
      videoContainer.push("video-container lg:w-1/2 px-ogs lg:pl-0 lg:pr-ogs");
      buttonStyle.push("");
    } else if (video_alignment === "Right") {
      container.push("lg:flex flex-row w-full max-w-screen-xl mx-auto");
      captionContainer.push("pt-6 lg:pt-0 lg:w-1/2 text-center");
      videoContainer.push("video-container lg:w-1/2 px-ogs lg:pr-0 lg:pl-ogs");
      buttonStyle.push("");
    } else {
      container.push("px-ogs w-full xl:w-1/2 mx-auto");
      captionContainer.push("pt-6 lg:pt-0 text-center");
    }
  } else if (video_width === "Reduced") {
    container.push("w-full");
    captionContainer.push("text-center md:-mb-12");
    videoContainer.push("w-full video-container px-ogs md:p-20");
    buttonStyle.push("px-ogs md:pl-20 md:pr-20 pt-8 md:pb-8");
  } else {
    container.push("w-full");
    captionContainer.push("text-center md:pb-12");
    videoContainer.push("w-full max-h-screen video-container");
  }

  return (
    <div className={classNames(container)}>
      {video_caption && (
        <div
          className={classNames(
            captionContainer,
            "px-ogs rte font-light text-base md:text-lg lg:text-xl leading-relaxed"
          )}
          dangerouslySetInnerHTML={{
            __html: video_caption.html
          }}
        ></div>
      )}

      <div className={classNames(videoContainer)}>
        <div className="relative h-full w-full">
          {video_embed && (
            <iframe
              className="w-full h-full object-cover"
              src={`https://www.youtube-nocookie.com/embed/${video_embed}?controls=0&showinfo=0&rel=0&loop=1&enablejsapi=1;playlist=${video_embed}`}
              frameBorder="0"
              controls
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default YoutubeEmbed;
