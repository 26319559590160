import React, { useState } from "react";
import Image from "gatsby-image";
import LinkWorkTo from "./../Link/LinkWorkTo";
import { CSSTransition } from "react-transition-group";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([EffectFade, Autoplay]);

function getDisplayIndex(index) {
  return index + 1 < 10 ? `0${index + 1}` : index + 1;
}

const Slider = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Swiper
      className="h-full"
      effect="fade"
      loop="true"
      allowTouchMove={false}
      speed={750}
      autoplay
      onSwiper={swiper => setActiveIndex(swiper.realIndex)}
      onSlideChange={swiper => setActiveIndex(swiper.realIndex)}
    >
      {data.map((item, index) => {
        const { data, uid } = item.work_item.document;
        return (
          <SwiperSlide key={index} className="w-full h-full relative bg-black">
            <React.Fragment>
              <LinkWorkTo to="/work/" aria-label="Our Work">
                {item.work_item_image.fluid ? (
                  <Image
                    className="w-full h-full hidden lg:block"
                    placeholderStyle={{ display: "none" }}
                    fluid={item.work_item_image.fluid}
                    loading="eager"
                  />
                ) : (
                  <div className="w-full h-full bg-grey-light" />
                )}
                {item.work_item_image_mobile.fluid ? (
                  <Image
                    className="w-full h-full lg:hidden"
                    placeholderStyle={{ display: "none" }}
                    fluid={item.work_item_image_mobile.fluid}
                    loading="eager"
                  />
                ) : (
                  <div className="w-full h-full bg-grey-light" />
                )}
              </LinkWorkTo>
              {data.title && (
                <div className="absolute bottom-0 inset-x-0 bg-gradient h-hh flex items-center">
                  <CSSTransition
                    in={activeIndex === index}
                    timeout={750}
                    classNames="fade-up"
                    unmountOnExit
                  >
                    <LinkWorkTo
                      className="flex items-center w-full h-full px-ogs index-work-link justify-end"
                      to={`/work/${uid}/`}
                    >
                      <div className="inline-flex items-center text-white text-lg md:text-xl lg:text-2xl leading-none uppercase index-work-link__el">
                        <div className="mr-2">{getDisplayIndex(index)}.</div>
                        <h2>{data.title}</h2>
                      </div>
                    </LinkWorkTo>
                  </CSSTransition>
                </div>
              )}
            </React.Fragment>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Slider;
