import React, { useEffect } from "react";
import Slider from "../Slider";
import scrollTo from "../../utils/scrollTo";
import { windowHeight } from "../../utils/windowDimensions";

const HomepageHero = ({ slice, auto_scroll }) => {
  const filteredWork = slice.items.filter(item => !!item.work_item.document);

  useEffect(() => {
    if (auto_scroll) {
      let timer;
      const startScroll = () => {
        timer = setTimeout(() => {
          if (window.scrollY === 0) {
            scrollTo(windowHeight);
          }
        }, 2500);
      };
      const stopScroll = () => {
        clearTimeout(timer);
      };
      startScroll();
      return () => {
        stopScroll();
      };
    }
  }, [auto_scroll]);

  return (
    <div className="w-full h-hero relative">
      <Slider data={filteredWork} />
      {slice.work_item?.document?.data.title && (
        <div className="absolute bottom-0 left-0 xl:left-auto xl:right-0 max-w-3xl xl:max-w-2xl z-50 pointer-events-none">
          <p className="text-white text-lg md:text-xl lg:text-2xl leading-snug">
            {slice.work_item?.document?.data.title}
          </p>
        </div>
      )}
    </div>
  );
};

export default HomepageHero;
