import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import Image from "gatsby-image";
import LinkIcon from "../../Link/LinkIcon";

const TextAndImageBlock = ({
  slice,
  index,
  parentContainerClassList,
  textPaddingClassList,
  imagesContainerClassList,
  aspectRatio,
  imageDescriptionPaddingClassList
}) => {
  const heading = slice.primary?.heading?.text;
  const text = slice.primary?.text?.html;
  const description = slice.primary?.description?.text;
  const imageTitle = slice.primary?.image_title?.text;
  const image = slice.primary?.image?.fluid;
  const linkText = slice.primary?.link_text?.text;
  const bgColour =
    slice.primary?.background_colour === "Grey" ? "md:bg-bleached-silk" : "";

  return (
    <div key={index} className={classNames(parentContainerClassList, bgColour)}>
      <div
        className={classNames(
          textPaddingClassList,
          "my-6 md:my-12 lg:my-0 lg:py-12"
        )}
      >
        {heading && (
          <h2 className="font-normal text-2xl lg:text-3xl xl:text-4xl leading-tight lg:leading-snug">
            {heading}
          </h2>
        )}
        {text && (
          <div
            className="mt-4 md:mt-6 text-base md:text-lg lg:text-xl leading-relaxed rte font-light"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        {linkText && (
          <Link
            to={slice.primary?.link.url}
            className="inline-flex gap-2 mt-10 items-center pt-2 link"
          >
            <div className="h-5 w-5">
              <LinkIcon />
            </div>
            {linkText && (
              <p className="tracking-wider pb-1 uppercase font-medium">
                {linkText}
              </p>
            )}
          </Link>
        )}
      </div>
      <div className={classNames(imagesContainerClassList)}>
        {image && (
          <Image
            placeholderStyle={{ display: "none" }}
            fluid={{ ...image, aspectRatio: aspectRatio }}
            objectCover
            loading="lazy"
          />
        )}
        {imageTitle && (
          <div className={classNames(imageDescriptionPaddingClassList)}>
            <h2 className="uppercase text-xs md:text-sm whitespace-no-wrap tracking-wider">
              {imageTitle}
            </h2>
            {description && (
              <p className="md:text-sm text-xs mt-2 md:mt-0 lg:pl-20 ">
                {description}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextAndImageBlock;
