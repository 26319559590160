import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "gatsby-image";
import LeftArrow from "../../Arrows/Left-arrow";
import RightArrow from "../../Arrows/Right-arrow";

const TestimonialBlock = ({ slice, index }) => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    swiperRef.current.slidePrev();
  };

  const handleNext = () => {
    swiperRef.current.slideNext();
  };
  return (
    <div>
      <h2 className="md:text-center font-normal text-2xl md:text-4xl">
        {slice.primary.testimonial_title.text}
      </h2>
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: true
        }}
        onSwiper={swiper => (swiperRef.current = swiper)}
      >
        {slice.items.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="flex flex-col-reverse lg:flex-row border border-primary-black-20 mt-12 md:mt-20">
              <div className="p-gs flex flex-col justify-between">
                <div className="flex justify-between items-center">
                  {item.project_name.text && (
                    <h3 className="font-light text-xl">
                      {item.project_name.text}
                    </h3>
                  )}
                  {item.category.text && (
                    <h3 className="px-3 py-1 text-sm font-light tracking-widest border border-primary-black-20 rounded-full uppercase">
                      {item.category.text}
                    </h3>
                  )}
                </div>
                <div>
                  {item.client_body.html && (
                    <div
                      className="mt-4 text-base rte md:text-lg lg:text-xl leading-relaxed"
                      dangerouslySetInnerHTML={{
                        __html: item.client_body.html
                      }}
                    />
                  )}
                </div>
                <div className="flex justify-between mt-10">
                  {item.client_name.text && (
                    <h3 className="font-light">{item.client_name.text}</h3>
                  )}
                  {slice.items.length > 1 && (
                    <div className="flex items-center gap-x-4">
                      <div className="cursor-pointer" onClick={handlePrev}>
                        <LeftArrow />
                      </div>
                      <div className="cursor-pointer" onClick={handleNext}>
                        <RightArrow />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {item.image.fluid && (
                <Image
                  className="flex-shrink-0 w-full lg:w-1/2"
                  placeholderStyle={{ display: "none" }}
                  fluid={{
                    ...item.image.fluid,
                    aspectRatio: 1.5
                  }}
                />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default TestimonialBlock;
